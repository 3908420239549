import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";

import {
  Button,
  Classes,
  Drawer,
  DrawerSize,
  Intent,
  Position,
  Switch,
  Card,
  Divider,
  ButtonGroup,
  HTMLSelect,
} from "@blueprintjs/core";

import { AuthContext } from "../providers/AuthProvider";

import { AppContext } from "../providers/AppProvider";

import PluginButton from "./PluginButton";

function AppDrawer() {
  const navigate = useNavigate();

  const { userConfig, setUserConfig, statusText, setStatusText } =
    useContext(AppContext);
  const { userRole, handleLogout } = useContext(AuthContext);

  const handleOpen = () => {
    setUserConfig({ sidebarOpen: true });
  };

  const handleClose = () => {
    setUserConfig({ sidebarOpen: false });
  };

  return (
    <>
      {!userConfig.sidebarOpen && (
        <>
          <div className="SlideOut">
            <Button icon="menu-open" large={true} onClick={handleOpen}>
              Menu
            </Button>
          </div>

          <Card
            compact
            className="BottomStatus"
            // style={{
            //   position: "absolute",
            //   bottom: "10px",
            //   left: "0",
            //   zIndex: 999,
            // }}
          >
            {statusText}
          </Card>
        </>
      )}

      <Drawer
        // className={this.props.data.themeName}
        icon="area-of-interest"
        isOpen={userConfig.sidebarOpen}
        onClose={handleClose}
        title="Portal.Blue Magical Portal Map"
        size={DrawerSize.SMALL}
        autoFocus={false}
        canOutsideClickClose={false}
        enforceFocus={false}
        hasBackdrop={false}
        position={Position.LEFT}
      >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            <ButtonGroup large={true} fill={true}>
              <Button
                icon="map"
                active={userConfig.showPortals}
                onClick={() =>
                  setUserConfig({ showPortals: !userConfig.showPortals })
                }
                intent={userConfig.showPortals ? Intent.PRIMARY : Intent.NONE}
              >
                Portals
              </Button>
              <Button
                icon="heatmap"
                active={userConfig.showHeatmap}
                onClick={() =>
                  setUserConfig({ showHeatmap: !userConfig.showHeatmap })
                }
                intent={userConfig.showHeatmap ? Intent.PRIMARY : Intent.NONE}
              >
                Heatmap
              </Button>
            </ButtonGroup>
            {/* Portals Config */}
            {userConfig.showPortals && (
              <>
                <Divider />
                <Switch
                  large={true}
                  labelElement={
                    <strong>
                      {userConfig.hideFactionAlignment
                        ? "Faction Alignment: Hidden"
                        : "Faction Alignment: Visible"}
                    </strong>
                  }
                  checked={!userConfig.hideFactionAlignment}
                  onChange={() =>
                    setUserConfig({
                      hideFactionAlignment: !userConfig.hideFactionAlignment,
                    })
                  }
                />
                {/* .enl {
  color: #03fe03;
}
.res {
  color: #00c5ff;
}
.mac {
  color: #ff2020;
}
.none {
  color: #fff;
} */}
                {!userConfig.hideFactionAlignment && (
                  <>
                    <ButtonGroup large={true} fill={true}>
                      {/* THERE shgould be buttons for toggleing each factions portals on and off */}
                      <Button
                        icon="shield"
                        active={userConfig.showResPortals}
                        style={{
                          backgroundColor: userConfig.showResPortals
                            ? "#00c5ff"
                            : "",
                        }}
                        onClick={() =>
                          setUserConfig({
                            showResPortals: !userConfig.showResPortals,
                          })
                        }
                        intent={
                          userConfig.showResPortals
                            ? Intent.PRIMARY
                            : Intent.NONE
                        }
                      >
                        R
                      </Button>
                      <Button
                        icon="flash"
                        active={userConfig.showEnlPortals}
                        style={{
                          backgroundColor: userConfig.showEnlPortals
                            ? "#03fe03"
                            : "",
                        }}
                        onClick={() =>
                          setUserConfig({
                            showEnlPortals: !userConfig.showEnlPortals,
                          })
                        }
                        intent={
                          userConfig.showEnlPortals
                            ? Intent.PRIMARY
                            : Intent.NONE
                        }
                      >
                        E
                      </Button>
                      <Button
                        icon="console"
                        active={userConfig.showMacPortals}
                        style={{
                          backgroundColor: userConfig.showMacPortals
                            ? "#ff2020"
                            : "",
                        }}
                        onClick={() =>
                          setUserConfig({
                            showMacPortals: !userConfig.showMacPortals,
                          })
                        }
                        intent={
                          userConfig.showMacPortals
                            ? Intent.PRIMARY
                            : Intent.NONE
                        }
                      >
                        M
                      </Button>
                      <Button
                        icon="refresh"
                        active={userConfig.showNeuPortals}
                        style={{
                          backgroundColor: userConfig.showNeuPortals
                            ? "#666"
                            : "",
                        }}
                        onClick={() =>
                          setUserConfig({
                            showNeuPortals: !userConfig.showNeuPortals,
                          })
                        }
                        intent={
                          userConfig.showNeuPortals
                            ? Intent.PRIMARY
                            : Intent.NONE
                        }
                      >
                        N
                      </Button>
                    </ButtonGroup>
                    <br />
                  </>
                )}

                <Switch
                  // visited
                  large={true}
                  labelElement={
                    <strong>
                      {userConfig.hideVisitedPortals
                        ? "Visited Portals: Hidden"
                        : "Visited Portals: Visible"}
                    </strong>
                  }
                  checked={!userConfig.hideVisitedPortals}
                  onChange={() =>
                    setUserConfig({
                      hideVisitedPortals: !userConfig.hideVisitedPortals,
                    })
                  }
                />
                <Switch
                  // captured

                  large={true}
                  labelElement={
                    <strong>
                      {userConfig.hideCapturedPortals
                        ? "Captured Portals: Hidden"
                        : "Captured Portals: Visible"}
                    </strong>
                  }
                  checked={!userConfig.hideCapturedPortals}
                  onChange={() =>
                    setUserConfig({
                      hideCapturedPortals: !userConfig.hideCapturedPortals,
                    })
                  }
                />
              </>
            )}
            {/* Agent Name:
        <input
          type="text"
          className="option agent"
          // onKeyUp="ia.app.setOption();"
        /> */}
            <br />
            <Divider />
            Follow GPS:
            <Switch labelElement={<strong>Enabled</strong>} />
            <Divider />
            Map Type
            <HTMLSelect
              large={true}
              fill={true}
              options={[
                { label: "Roads", value: "roads" },
                { label: "Terrain", value: "terrain" },
                { label: "Satellite", value: "satellite" },
              ]}
              onChange={(e) => {
                setUserConfig({ mapType: e.target.value });
              }}
              value={userConfig.mapType}
            />
            Map Style
            <HTMLSelect
              large={true}
              fill={true}
              options={[
                { label: "Light", value: "light" },
                { label: "Dark", value: "dark" },
                { label: "White", value: "white" },
                { label: "Black", value: "black" },
              ]}
              onChange={(e) => {
                setUserConfig({ mapStyle: e.target.value });
              }}
              value={userConfig.mapStyle}
            />
          </div>
        </div>
        <div
          className={Classes.DRAWER_FOOTER}
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <PluginButton />

          <Button intent={Intent.WARNING} onClick={handleLogout}>
            Logout
          </Button>
        </div>

        {userRole == 100 && (
          <div
            className={Classes.DRAWER_FOOTER}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Button intent={Intent.NONE} onClick={() => navigate("/admin")}>
              Admin Panel
            </Button>
          </div>
        )}
        <div className={Classes.DRAWER_FOOTER}>
          <Card
            compact
            // style={{
            //   position: "absolute",
            //   bottom: "10px",
            //   left: "0",
            //   zIndex: 999,
            // }}
          >
            {statusText}
          </Card>
        </div>
      </Drawer>
    </>
  );
}

export default AppDrawer;
