import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

import { AppProviderContext } from "./providers/AppProvider";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <AppProviderContext>
      <App />
    </AppProviderContext>
  </React.StrictMode>
);
