import React, { createContext, useCallback, useEffect, useState } from "react";

import axios from "axios";

const DEV_ENDPOINT = "http://localhost:3010";
const BACKEND_URL = process.env.NODE_ENV === "production" ? "" : DEV_ENDPOINT;

const AuthContext = createContext(null);

const AuthProviderContext = ({ children }) => {
  // const [isLoginLoading, setLoginLoading] = useState(true);

  const [loginStatus, setLoginStatus] = useState("pending");

  const [userRole, setUserRole] = useState(null);

  const initialRefreshToken = localStorage.getItem("refreshToken");
  const [refreshToken, _setRefreshToken] = useState(initialRefreshToken);
  const setRefreshToken = (token: string) => {
    localStorage.setItem("refreshToken", token);
    _setRefreshToken(token);
  };

  const [accessToken, setAccessToken] = useState("");
  // const [pluginToken, setPluginToken] = useState("");

  const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  // on error check if it is "jwt expired" and get a new access token
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.error("axiosInstance error", error);

      // "jwt expired"
      if (
        error.response.status === 401 &&
        error.response.data.message === "jwt expired"
      ) {
        console.log("jwt expired refreshToken", refreshToken);
        await exchangeRefreshToken();

        // return axiosInstance.request(error.config);
      }

      return Promise.reject(error);
    }
  );

  // update the access token in axiosInstance when it changes
  useEffect(() => {
    if (axiosInstance && accessToken) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
    }
  }, [axiosInstance, accessToken]);

  async function exchangeRefreshToken() {
    // setLoginLoading(true);
    setLoginStatus("loading");

    console.log("refreshToken set/updated", refreshToken);

    try {
      const response = await axios.get(`${BACKEND_URL}/user/token/access`, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });

      const data = response.data;
      console.log("exchanged refresh for access", data);

      setUserRole(data.userRole);

      setAccessToken(data.accessToken);
      // setLoginLoading(false);

      setLoginStatus("logged_in");
    } catch (error) {
      console.error("exchangeRefreshToken error", error);
      setRefreshToken(null);
      // setLoginLoading(false);

      setLoginStatus("error");
    }
  }

  // exchange the refresh token for an access token on load
  useEffect(() => {
    if (refreshToken) {
      exchangeRefreshToken();
    } else {
      // setLoginLoading(false);
      setLoginStatus("logged_out");
    }
  }, [refreshToken]);

  // should be re-implemented as a timer? that checks when the token expires
  // useEffect(() => {
  //   console.log("status", status, userData);

  //   if (status === "success" && userData?.success === true) {
  //     dispatch(setUserData(userData));
  //     dispatch(setLoginLoading(false));
  //   }

  //   if (status === "error") {
  //     console.error("APP setUserData error", error);

  //     enqueueSnackbar("Your session has expired. Please log in again!", {
  //       variant: "error",
  //     });
  //     dispatch(setLoggedOut("session expired"));
  //     dispatch(setLoginLoading(false));
  //   }
  // }, [status]);

  const performLogin = async ({ email, password }) => {
    const response = await axios.post(`${BACKEND_URL}/auth/email/login`, {
      email,
      password,
    });

    const data = response.data;
    console.log("performLogin data", data);

    if (data.success) {
      setRefreshToken(data.refreshToken);
    }

    return data;
  };

  const performRegister = async ({ email, password }) => {
    const response = await axios.post(`${BACKEND_URL}/auth/email/register`, {
      email,
      password,
    });

    const data = response.data;
    console.log("performRegister data", data);

    return data;
  };

  const performGoogleLogin = async (googleAuthCode: string) => {
    const response = await axios.post(`${BACKEND_URL}/auth/google/code`, {
      code: googleAuthCode,
    });

    const data = response.data;
    console.log("performGoogleLogin data", data);

    if (data.success) {
      setRefreshToken(data.refreshToken);
    }

    return data;
  };

  const fetchExistingTokens = async (type: string = null) => {
    const response = await axiosInstance.get(`/user/token`, {
      params: {
        type: type,
      },
    });

    const data = response.data;
    console.log("fetchNewPluginToken data", data);

    return data;
  };

  const fetchNewPluginToken = async () => {
    const response = await axiosInstance.get(`/user/token/plugin`);

    const data = response.data;
    console.log("fetchNewPluginToken data", data);

    return data;
  };

  const handleLogout = () => {
    setLoginStatus("logged_out");
    setUserRole(null);

    setRefreshToken(null);
    setAccessToken("");
  };

  return (
    <AuthContext.Provider
      value={{
        isLoginLoading: loginStatus === "loading" || loginStatus === "pending",
        loginStatus,
        userRole,

        refreshToken,
        axiosInstance,

        fetchExistingTokens,
        fetchNewPluginToken,

        performLogin,
        performRegister,

        performGoogleLogin,

        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProviderContext };
