import React, { useEffect, useContext } from "react";

import {
  HashRouter,
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";

import { FocusStyleManager, PortalProvider } from "@blueprintjs/core";

import { AuthContext, AuthProviderContext } from "./providers/AuthProvider";

import LoginPage from "./pages/LoginPage";
import MapPage from "./pages/MapPage";

import "maplibre-gl/dist/maplibre-gl.css";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import "./scss/App.scss";

FocusStyleManager.onlyShowFocusOnTabs();

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let location = useLocation();

  const { isLoginLoading, refreshToken, accessToken } = useContext(AuthContext);

  if (isLoginLoading) return null;

  if (!refreshToken) {
    // props.dispatch(setAuthOpen(true));
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // console.log("userData", userData);

  // if (requireRole && userData.role < requireRole) {
  //   // props.dispatch(setAuthOpen(true));
  //   return <Navigate to="/" state={{ from: location }} replace />;
  // }

  return children;
};

export default function App() {
  return (
    <PortalProvider>
      <AuthProviderContext>
        <HashRouter>
          <div className="mainContainer">
            <Routes>
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <MapPage />
                  </RequireAuth>
                }
              />
              <Route path="/login" element={<LoginPage />} />
              {/* <Route
                  path="/map"
                  element={
                    <RequireAuth>
                      <MapPage />
                    </RequireAuth>
                  }
                /> */}
            </Routes>
          </div>
        </HashRouter>
      </AuthProviderContext>
    </PortalProvider>
  );
}
