import React, { createContext, useContext, useEffect, useState } from "react";

import axios from "axios";

import { AuthContext, AuthProviderContext } from "./AuthProvider";

const DEV_ENDPOINT = "http://localhost:3010";
const URL = process.env.NODE_ENV === "production" ? "" : DEV_ENDPOINT;

const PortalContext = createContext(null);

const PortalProviderContext = ({ children }) => {
  const { axiosInstance } = useContext(AuthContext);

  ///  { cellId: "pending|loading|loaded"}
  const [cellState, setCellState] = useState({});

  /// { cellId: Portal[] }
  const [loadedCells, setLoadedCells] = useState({});

  // request a cell be loaded
  // const requestCell = async (cellId: string, zoom: number = 10) => {
  //   // console.log("request cell", cellId);

  //   if (cellState[cellId] === "pending") {
  //     return;
  //   }

  //   if (cellState[cellId] === "loaded") {
  //     return;
  //   }

  //   setCellState({ ...cellState, [cellId]: "pending" });

  //   try {
  //     const response = await axiosInstance.get(`/portal/s2/${zoom}/${cellId}`);

  //     const data = response.data;

  //     setLoadedCells({ ...loadedCells, [cellId]: data });

  //     setCellState({ ...cellState, [cellId]: "loaded" });
  //   } catch (e) {
  //     console.error(e);
  //     setCellState({ ...cellState, [cellId]: "error" });
  //   }
  // };

  const [portalsLoading, setPortalsLoading] = useState(false);
  const [loadedPortals, setLoadedPortals] = useState([]);

  // request bound be loaded
  const requestBounds = async (
    neLat: number,
    neLng: number,
    swLat: number,
    swLng: number
  ) => {
    // console.log("request cell", cellId);

    // if (cellState[cellId] === "pending") {
    //   return;
    // }

    // if (cellState[cellId] === "loaded") {
    //   return;
    // }

    // setCellState({ ...cellState, [cellId]: "pending" });

    setPortalsLoading(true);

    try {
      const response = await axiosInstance.get(
        `/portal/bound/${neLat}/${neLng}/${swLat}/${swLng}`
      );

      const data = response.data;

      console.log("loaded bounds", data);

      setLoadedPortals(data.data);
      setPortalsLoading(false);
    } catch (e) {
      console.error(e);
      setPortalsLoading(false);
    }

    // setLoadedCells({ ...loadedCells, [cellId]: data });

    // // console.log("loaded cell", cellId, data);

    // setCellState({ ...cellState, [cellId]: "loaded" });

    // setLoadedCells([...loadedCells, cell]);
  };

  // const performLogin = async ({ email, password }) => {
  //   const response = await axios.post(`${URL}/auth/email/login`, {
  //     email,
  //     password,
  //   });

  //   const data = response.data;

  //   if (data.success) {
  //     setUserToken(data.user.token);
  //   }

  //   return data;
  // };

  // const performGoogleLogin = async (googleAuthCode: string) => {
  //   const response = await axios.post(`${URL}/auth/google/code`, {
  //     code: googleAuthCode,
  //   });

  //   const data = response.data;
  //   console.log("data", data);

  //   if (data.success) {
  //     setUserToken(data.user.token);
  //   }

  //   return data;
  // };

  // const performRegister = async ({ email, password }) => {
  //   const response = await axios.post(`${URL}/auth/email/register`, {
  //     email,
  //     password,
  //   });

  //   const data = response.data;

  //   if (data.success) {
  //     setUserToken(data.token);
  //   }

  //   return data;
  // };

  // const getNewKey = async () => {
  //   const response = await axios.get(`${URL}/user/token/new`, {
  //     headers: {
  //       Authorization: `Bearer ${userToken}`,
  //     },
  //   });

  //   const data = response.data;

  //   return data;
  // };

  return (
    <PortalContext.Provider
      value={{
        cellState,
        loadedCells,

        portalsLoading,
        loadedPortals,
        requestBounds,
      }}
    >
      {children}
    </PortalContext.Provider>
  );
};

export { PortalContext, PortalProviderContext };
