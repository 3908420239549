import React, { createContext, useContext, useEffect, useState } from "react";

const AppContext = createContext(null);

type IUserConfig = {
  mapCenter: [number, number];
  mapZoom: number;

  mapType: "roads" | "terrain" | "satellite";
  mapStyle: "light" | "white" | "dark" | "black";

  sidebarOpen: boolean;

  showPortals: boolean;
  showHeatmap: boolean;

  heatmapMode: "visited" | "captured" | "none";

  hideFactionAlignment: boolean;

  showResPortals: boolean;
  showEnlPortals: boolean;
  showNeuPortals: boolean;
  showMacPortals: boolean;

  hideVisitedPortals: boolean;
  hideCapturedPortals: boolean;

  followUserLocation: boolean;
};

const AppProviderContext = ({ children }) => {
  const [statusText, setStatusText] = useState("Loading Javascript...");

  const defaultConfig: IUserConfig = {
    // --- map display
    mapCenter: [134, -28],
    mapZoom: 4,

    mapType: "roads",
    mapStyle: "light",

    // --- sidebar display
    sidebarOpen: true,

    // --- mode choice
    showPortals: true,
    showHeatmap: false,

    // --- heatmap config
    heatmapMode: "visited", // captured, visited, none

    // --- portals config
    showResPortals: true,
    showEnlPortals: true,
    showNeuPortals: true,
    showMacPortals: true,
    hideFactionAlignment: false,
    hideVisitedPortals: false,
    hideCapturedPortals: false,
    followUserLocation: false,
  };

  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    defaultConfig.mapStyle = "dark";
  }

  const loadedConfig: any = JSON.parse(localStorage.getItem("config"));

  // the config arrays shoudl be merged, and the loadedConfig should overwrite the defaultConfig
  const initialConfig: IUserConfig = {
    ...defaultConfig,
    ...loadedConfig,
  };

  const [userConfig, _setUserConfig] = useState<IUserConfig>(initialConfig);
  const setUserConfig = (updateObject: Partial<IUserConfig>) => {
    // updateObject is a partial IUserConfig object

    _setUserConfig((prevConfig) => {
      const newConfig = {
        ...prevConfig,
        ...updateObject,
      };

      console.log("setUserConfig", prevConfig, updateObject);

      localStorage.setItem("config", JSON.stringify(newConfig));
      return newConfig;
    });
  };

  return (
    <AppContext.Provider
      value={{
        userConfig,
        setUserConfig,

        statusText,
        setStatusText,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProviderContext };
