import React, { useState, useMemo, useContext } from "react";

import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Elevation,
  Divider,
  H5,
  Icon,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Spinner,
  Switch,
  Tag,
  Tooltip,
} from "@blueprintjs/core";

// import { PortalContext } from "../providers/PortalProvider";
import { AuthContext } from "../providers/AuthProvider";

import { FcGoogle } from "react-icons/fc";

import {
  GoogleOAuthProvider,
  useGoogleLogin,
  GoogleLogin as GLLogin,
} from "@react-oauth/google";

function LoginButton({
  // isLoading,
  onLoading,
  onLoginSuccess,
  onLoginFailure,
  // paletteMode,
}) {
  const [isLoading, setIsLoading] = React.useState(false);

  const updateLoading = (loading) => {
    // setIsLoading(loading);
    onLoading(loading);
  };

  const login = useGoogleLogin({
    onSuccess: async (creds) => {
      // updateLoading(false);
      onLoginSuccess(creds);
    },
    // onFailure: (error) => {
    //   updateLoading(false);
    //   onLoginFailure(error);
    // },
    onNonOAuthError: (error) => {
      updateLoading(false);
      // onLoginFailure(error);
    },
    flow: "auth-code",
    // useOneTap: true,
  });

  return (
    <Button
      // color="secondary"
      // onClick={handleClick}
      loading={isLoading}
      onClick={() => {
        setIsLoading(true);
        login();
      }}
      icon={<FcGoogle />}
      // intent={Intent.PRIMARY}
      // style={{ backgroundColor: "#4285F4" }}
      large
      fill
    >
      Log in with Google
    </Button>
  );

  // return <GoogleLoginButton onClick={() => login()} />;
}

export function GoogleLoginButton(props) {
  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID_WEB}>
      <LoginButton {...props} />
    </GoogleOAuthProvider>
  );
}

import "../scss/LoginPage.scss";
function LoginPage() {
  const { performGoogleLogin, performLogin, performRegister } =
    useContext(AuthContext);

  const emailRef = React.useRef(null);
  const passwordInputRef = React.useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [loginLoading, setLoginLoading] = useState(false);

  const loginPossible = useMemo(() => {
    return email.length > 0 && password.length > 0;
  }, [email, password]);

  const navigate = useNavigate();

  const handleLogin = async (event?: React.MouseEvent<HTMLButtonElement>) => {
    try {
      // lock password
      setShowPassword(false);
      setLoginLoading(true);

      // Replace with actual authentication logic
      const loggin = await performLogin({ email, password });

      if (loggin.success) {
        console.log("Login success", loggin);

        // setUserToken(loggin.user.token);

        navigate("/");
      } else {
        console.log("Login failed");
        alert("Login failed");
      }
    } catch (error) {
      console.error("Login error", error);
      alert("Login error");
    } finally {
      setLoginLoading(false);
    }
  };

  const handleRegister = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setLoginLoading(true);

      // Replace with actual authentication logic
      const loggin = await performRegister({ email, password });

      if (loggin.success) {
        console.log("Register success", loggin);

        alert("Register success");

        // setUserToken(loggin.user.token);

        // navigate("/map");
      } else {
        console.log("Register failed");
        alert("Register failed");
      }
    } catch (error) {
      console.error("Register error", error);
      alert("Register error");
    } finally {
      setLoginLoading(false);
    }
  };

  const handleGoogleLoginSuccess = async (data: any) => {
    try {
      setLoginLoading(true);

      // Replace with actual authentication logic
      const loggin = await performGoogleLogin(data.code);

      if (loggin.success) {
        console.log("Login success", loggin);

        // setUserToken(loggin.user.token);

        navigate("/");
      } else {
        console.log("Login failed");
        alert("Login failed");
      }
    } catch (error) {
      console.error("Login error", error);
      alert("Login error");
    } finally {
      setLoginLoading(false);
    }
  };

  // focus email
  React.useEffect(() => {
    emailRef.current && emailRef.current.focus();
  }, []);

  const lockButton = (
    <Tooltip
      content={`${showPassword ? "Hide" : "Show"} Password`}
      disabled={loginLoading}
    >
      <Button
        disabled={loginLoading}
        icon={showPassword ? "unlock" : "lock"}
        intent={showPassword ? Intent.WARNING : Intent.SUCCESS}
        minimal={true}
        onClick={() => setShowPassword(!showPassword)}
      />
    </Tooltip>
  );

  return (
    <div className="LoginPage">
      <Card
        elevation={Elevation.TWO}
        style={{
          display: "flex",
          width: "100%",
          maxWidth: "350px",
          margin: "auto",
          padding: "20px",
          marginTop: "100px",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          // justifyContent: "center",
        }}
      >
        <img
          src="/octopus-blue.png"
          alt="logo"
          style={{ width: "100px", height: "100px", marginBottom: "10px" }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: "100%",
          }}
        >
          <InputGroup
            inputRef={emailRef}
            large
            disabled={loginLoading}
            placeholder="Email Address"
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyUp={(event?: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter" && !loginPossible) {
                // focus on password input
                passwordInputRef.current && passwordInputRef.current.focus();
              } else if (event.key === "Enter" && loginPossible) {
                handleLogin();
              }
            }}
          />

          <InputGroup
            inputRef={passwordInputRef}
            // {...sharedProps}
            large
            disabled={loginLoading}
            placeholder="Password"
            rightElement={lockButton}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={(event?: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter" && loginPossible) {
                handleLogin();
              }
            }}
          />

          <div
            style={{
              display: "flex",
              flexGrow: 1,
              gap: "8px",
              // soace evenly
              justifyContent: "space-evenly",
            }}
          >
            <Button
              onClick={handleLogin}
              intent={Intent.PRIMARY}
              disabled={!loginPossible || loginLoading}
              fill
            >
              Login
            </Button>
            <Button
              onClick={handleRegister}
              // intent={Intent.}
              disabled={!loginPossible || loginLoading}
              fill
            >
              Register
            </Button>
          </div>
        </div>

        <Divider />

        <GoogleLoginButton onLoginSuccess={handleGoogleLoginSuccess} />
      </Card>
    </div>
  );
}

export default LoginPage;
