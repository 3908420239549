import React, { useEffect, useContext, useRef, useCallback } from "react";

import maplibregl from "maplibre-gl";

// import mlcontour from "maplibre-contour";

import { s2, geojson } from "s2js";

import { getCellVisualization } from "../lib/GeoUtils";

import { Polygon } from "geojson";

import { PortalContext } from "../providers/PortalProvider";
import { AppContext } from "../providers/AppProvider";

import "../scss/PortalMap.scss";
const PortalMap = () => {
  const { userConfig, setUserConfig } = useContext(AppContext);

  const {
    cellState,
    loadedCells,
    // requestCell,

    portalsLoading,
    loadedPortals,
    requestBounds,
  } = useContext(PortalContext);

  // let map: maplibregl.Map;
  const mapRef = useRef<maplibregl.Map | null>(null);
  // const regionsRef = React.useRef<any[]>([]);

  // should be an objhect containing arrays of markers for each cell
  type IMarkersRefType = {
    [cellId: string]: HTMLElement[];
  };
  const markersRef = React.useRef<IMarkersRefType>({});

  function getCellsForViewport() {
    const bounds = mapRef.current.getBounds();

    // console.log(bounds);

    const poly: Polygon = {
      type: "Polygon",
      coordinates: [
        [
          [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
          [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
          [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
          [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
          [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
        ],
      ],
    };

    const coverer = new geojson.RegionCoverer({
      minLevel: 6,
      maxLevel: 6,
      maxCells: 25,
    });

    const covering: s2.CellUnion = coverer.covering(poly);

    // // console.log("covering", covering);
    // console.log(
    //   "covering",
    //   [...covering].map((c) => s2.cellid.toToken(c)).join(", ")
    // );

    return covering;

    // const s2Cells = s2.getCover(
    //   bounds.getSouthWest().lng,
    //   bounds.getSouthWest().lat,
    //   bounds.getNorthEast().lng,
    //   bounds.getNorthEast().lat,
    //   10

    // );

    // return s2Cells;
  }

  function getIconName(
    team: "R" | "E" | "M" | "N",
    visited: boolean,
    captured: boolean
  ) {
    const factionPrefix = {
      R: "res",
      E: "enl",
      N: "neu",
      M: "mac",
    };

    const postFix = {
      visited: "-cross",
      captured: "-v",
    };
    return `${factionPrefix[team]}${
      captured ? postFix["captured"] : visited ? postFix["visited"] : ""
    }.png`;
  }

  function getMarkerForPortalData(portData: any): HTMLDivElement {
    let visitMod = "none";

    if (portData.history?.visited) {
      visitMod = "visited";
    }

    // captured = faded out

    if (portData.history?.captured) {
      visitMod = "captured";
    }

    let theIcons = getIconName("N", false, false);
    // let theIcons = iconArray["N"]["plain"][visitMod];

    if (portData.team) {
      // console.log("portal.team", portal.team);
      theIcons = getIconName(
        portData.team,
        portData.history?.visited,
        portData.history?.captured
      );
    }

    // create a DOM element for the marker
    const el = document.createElement("div");
    el.className = "marker";
    el.style.backgroundImage = `url(/icons/${theIcons})`;
    el.style.width = `17px`;
    el.style.height = `17px`;

    return el;
  }
  // function renderCell(s2CellId: string, status: string, portalData: any = []) {}

  const [mapLoaded, setMapLoaded] = React.useState(false);

  // loading via portals list (viewport)
  React.useEffect(() => {
    if (!mapLoaded) return;
    const pointGeoJsonData = {
      type: "FeatureCollection",
      features: [],
    };
    for (const portalData of loadedPortals) {
      console.log("portalData", portalData);

      // for (const portal in loadedCells[cellId].data) {
      pointGeoJsonData.features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [portalData.lng, portalData.lat],
        },
        properties: {
          icon: getIconName(
            portalData.team,
            portalData.history?.visited,
            portalData.history?.captured
          ),
          guid: portalData.guid,
          title: portalData.title,
          lat: portalData.lat,
          lng: portalData.lng,
          description: portalData.description,
        },
      });
    }

    console.log("pointGeoJsonData", pointGeoJsonData);

    const portalSource: maplibregl.GeoJSONSource = mapRef.current.getSource(
      "portals"
    ) as maplibregl.GeoJSONSource;

    if (portalSource?.setData) portalSource.setData(pointGeoJsonData as any);
  }, [portalsLoading, loadedPortals]);

  React.useEffect(() => {
    if (!mapLoaded || !mapRef.current) return;

    // console.log("loadedCells", loadedCells);

    const mapType: string = "geojson";

    if (mapType == "markers") {
      for (const cellId in cellState) {
        // console.log("cellId", cellId);

        if (cellState[cellId] == "loaded") {
          // delete current markers and remove from map
          if (markersRef.current[cellId]) {
            for (const marker of markersRef.current[cellId]) {
              marker.remove();
            }
            markersRef.current[cellId] = [];
          }

          if (loadedCells[cellId].data.length > 0) {
            // loadedCells[cellId].data.map((portal) => {

            for (const portal of loadedCells[cellId].data) {
              console.log("portal", portal);
              /// portal style/color icon
              const el = getMarkerForPortalData(portal);

              el.addEventListener("click", () => {
                alert(portal.title);
              });

              // add marker to map
              new maplibregl.Marker({ element: el })
                .setLngLat([portal.lng, portal.lat])
                .addTo(mapRef.current);

              if (!markersRef.current[cellId]) {
                markersRef.current[cellId] = [];
              }
              markersRef.current[cellId].push(el);
            }

            //   const el = document.createElement("div");
            //   el.className = "marker";
            //   el.style.backgroundImage = `url(/icons/neu.png)`;
            //   el.style.width = `50px`;
            //   el.style.height = `50px`;

            //   // el.addEventListener("click", () => {
            //   //   // window.alert(portal.);c
            //   // });

            //   // add marker to map

            //   new maplibregl.Marker({ element: el })
            //     .setLngLat([portData.lng, portData.lat])
            //     .addTo(map);
            // }
          }
        }
      }
    }

    if (mapType == "geojson") {
      const pointGeoJsonData = {
        type: "FeatureCollection",
        features: [],
      };
      for (const cellId in cellState) {
        // console.log("cellId", cellId);

        if (cellState[cellId] == "loaded") {
          // for (const portal in loadedCells[cellId].data) {
          pointGeoJsonData.features.push(
            ...loadedCells[cellId].data.map((portal) => {
              // console.log("portal", portal);
              return {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [portal.lng, portal.lat],
                },
                properties: {
                  icon: "neu.png",
                  title: portal.name,
                  description: portal.description,
                },
              };
            })
          );
        }
      }

      console.log("pointGeoJsonData", pointGeoJsonData);

      const portalSource: maplibregl.GeoJSONSource = mapRef.current.getSource(
        "portals"
      ) as maplibregl.GeoJSONSource;

      if (portalSource?.setData) portalSource.setData(pointGeoJsonData as any);
    }
  }, [mapLoaded, loadedCells]);

  const onMapSettle = () => {
    // get centre and zoom and update config
    const mapCenter = mapRef.current.getCenter();
    const mapZoom = mapRef.current.getZoom();

    console.log("mapCenter", mapCenter, "mapZoom", mapZoom);
    setUserConfig({ mapCenter, mapZoom });

    // too small
    // if (mapZoom < 7) return;

    const cells = getCellsForViewport();

    const visual = getCellVisualization(cells);

    console.log("visual", visual);

    const geoJsonSource: maplibregl.GeoJSONSource = mapRef.current.getSource(
      "covering"
    ) as maplibregl.GeoJSONSource;

    if (geoJsonSource?.setData) geoJsonSource.setData(visual);

    // for (const cellid of cells) {
    //   console.log("cells", s2.cellid.toToken(cellid));
    //   requestCell(s2.cellid.toToken(cellid));
    // }

    // get all portals in biound

    const bounds = mapRef.current.getBounds();

    requestBounds(
      bounds.getNorthEast().lat,
      bounds.getNorthEast().lng,
      bounds.getSouthWest().lat,
      bounds.getSouthWest().lng
    );

    // [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
    //   [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
    //   [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
    //   [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
    //   [bounds.getNorthEast().lng, bounds.getNorthEast().lat],

    // map.getSource("covering").setData(cells);}
  };

  function isWebglSupported() {
    if (window.WebGLRenderingContext) {
      const canvas = document.createElement("canvas");
      try {
        // Note that { failIfMajorPerformanceCaveat: true } can be passed as a second argument
        // to canvas.getContext(), causing the check to fail if hardware rendering is not available. See
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/getContext
        // for more details.
        const context =
          canvas.getContext("webgl2") || canvas.getContext("webgl");
        if (context && typeof context.getParameter == "function") {
          return true;
        }
      } catch (e) {
        // WebGL is supported, but disabled
      }
      return false;
    }
    // WebGL not supported
    return false;
  }

  // updater map style
  // useEffect(() => {
  //   if (mapRef.current) {c
  //     mapRef.current.setStyle(
  //       `https://api.protomaps.com/styles/v3/${userConfig.mapStyle}.json?key=d6ddc1b4b2acd3aa`
  //     );
  //   }
  // }, [userConfig.mapStyle]);

  const mapStyle = React.useMemo(() => {
    if (userConfig.mapType === "roads") {
      return `https://api.protomaps.com/styles/v3/${userConfig.mapStyle}.json?key=d6ddc1b4b2acd3aa`;
    } else if (userConfig.mapType === "terrain") {
      return "https://api.protomaps.com/styles/v3/black.json?key=d6ddc1b4b2acd3aa";
    } else if (userConfig.mapType === "satellite") {
      return "https://api.maptiler.com/maps/hybrid/style.json?key=b6JHLeYTMuWBcRLbQjPI";
    }
  }, [userConfig.mapType, userConfig.mapStyle]);

  // updater map type
  useEffect(() => {
    if (mapRef.current) {
      const transformStyle = (previousStyle, nextStyle) => ({
        // keep portals and covering stroke

        ...nextStyle,
        // layers: previousStyle.layers,
        // sources: {
        //   // copy portals and covering
        //   portals: previousStyle.sources.portals,
        //   covering: previousStyle.sources.covering,
        //   ...nextStyle.sources,
        // },
      });

      mapRef.current.setStyle(mapStyle, {
        diff: true,
        transformStyle,
      });

      // const existingLayers = mapRef.current.getStyle().layers;
      // existingLayers.forEach((layer) => {
      //   if (layer.id !== "portals" && layer.id !== "covering-stroke") {
      //     mapRef.current.addLayer(layer);
      //   }
      // });
    }
  }, [mapStyle]);

  /** Initial Map Configuration */
  React.useEffect(() => {
    setMapLoaded(false);

    if (!isWebglSupported()) {
      alert("Your browser does not support WebGL");
      throw new Error("WebGL not supported");
    }

    // THEME/STYLES

    let basemapTheme = "white";
    let s2CellBorderColor = "darkslategray";

    if (userConfig.mapStyle) {
      basemapTheme = "black";

      s2CellBorderColor = "yellow";
    }

    if (maplibregl.getRTLTextPluginStatus() === "unavailable") {
      maplibregl.setRTLTextPlugin(
        "https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.min.js",
        true
      );
    }

    mapRef.current = new maplibregl.Map({
      container: "PortalMap",
      style: `https://api.protomaps.com/styles/v3/${basemapTheme}.json?key=d6ddc1b4b2acd3aa`,
      maplibreLogo: true,
      logoPosition: "bottom-right",

      center: userConfig.mapCenter,
      zoom: userConfig.mapZoom,
    });

    mapRef.current.addControl(
      new maplibregl.NavigationControl({
        showCompass: false,
        visualizePitch: false,
      }),
      "top-right"
    );

    // disable map rotation using right click + drag
    mapRef.current.dragRotate.disable();

    // disable map rotation using keyboard
    mapRef.current.keyboard.disable();

    // disable map rotation using touch rotation gesture
    mapRef.current.touchZoomRotate.disableRotation();

    mapRef.current.addControl(new maplibregl.FullscreenControl());

    mapRef.current.on("load", async () => {
      const images = [
        "enl.png",
        "enl-v.png",
        "enl-cross.png",
        "res.png",
        "res-v.png",
        "res-cross.png",
        "neu.png",
        "neu-v.png",
        "neu-cross.png",
        "mac.png",
        "mac-v.png",
        "mac-cross.png",
      ];

      for (const imageName of images) {
        const image = await mapRef.current.loadImage(`/icons/${imageName}`);
        mapRef.current.addImage(imageName, image.data);
      }

      let geolocate = new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      });

      mapRef.current.addControl(geolocate);

      geolocate.on("trackuserlocationend", () => {
        console.log("A trackuserlocationend event has occurred.");
      });

      mapRef.current.on("dragend", () => {
        console.log("dragend");
        onMapSettle();
      });
      mapRef.current.on("zoomend", (e) => {
        console.log("zoomend", e);
        onMapSettle();
      });

      mapRef.current.addSource("portals", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });
      mapRef.current.addLayer({
        id: "portals",
        source: "portals",
        type: "symbol",
        minzoom: 6,
        maxzoom: 24,
        layout: {
          "icon-image": [
            "coalesce",
            ["image", ["get", "icon"]],
            ["image", "neu.png"],
          ],
          // "text-field": ["get", "title"],
          // "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          // "text-offset": [0, 1.25],
          // "circle-radius": 6,
          // "circle-color": "#B42222",
        },
      });

      mapRef.current.on("click", "portals", (e) => {
        console.log("click", e);

        const properties = e.features[0].properties;
        console.log("click properties", properties);

        const popupHtml = `
          <h3>${properties.title}</h3>
          <p>${properties.description}</p>
          <p>${properties.lat}, ${properties.lng}</p>
          <p><a href="https://intel.ingress.com/?pll=${properties.lat},${properties.lng}" target="_blank">Intel</a></p>
        `;

        new maplibregl.Popup()
          .setLngLat([properties.lng, properties.lat])
          .setHTML(popupHtml)
          .addTo(mapRef.current);
      });

      // Create a popup, but don't add it to the map yet.
      const popup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      mapRef.current.on("mouseenter", "portals", (e) => {
        // Change the cursor style as a UI indicator.
        mapRef.current.getCanvas().style.cursor = "pointer";

        const properties = e.features[0].properties;
        console.log("click properties", properties);

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup
          .setLngLat([properties.lng, properties.lat])
          .setHTML(properties.title)
          .addTo(mapRef.current);
      });
      mapRef.current.on("mouseleave", "portals", () => {
        mapRef.current.getCanvas().style.cursor = "";
        popup.remove();
      });

      mapRef.current.addSource("covering", {
        type: "geojson",
        data: {
          type: "MultiPolygon",
          coordinates: [],
        },
      });

      mapRef.current.addLayer({
        id: "covering-stroke",
        source: "covering",
        type: "line",
        paint: {
          "line-color": s2CellBorderColor,
          "line-width": 0.1,
          "line-opacity": 0.2,
        },
      });

      // Add a geojson point source.
      // Heatmap layers also work with a vector tile source.
      // mapRef.current.addSource("earthquakes", {
      //   type: "geojson",
      //   data: "https://maplibre.org/maplibre-gl-js/docs/assets/earthquakes.geojson",
      // });

      // mapRef.current.addLayer(
      //   {
      //     id: "earthquakes-heat",
      //     type: "heatmap",
      //     source: "earthquakes",
      //     maxzoom: 9,
      //     paint: {
      //       // Increase the heatmap weight based on frequency and property magnitude
      //       "heatmap-weight": [
      //         "interpolate",
      //         ["linear"],
      //         ["get", "mag"],
      //         0,
      //         0,
      //         6,
      //         1,
      //       ],
      //       // Increase the heatmap color weight weight by zoom level
      //       // heatmap-intensity is a multiplier on top of heatmap-weight
      //       "heatmap-intensity": [
      //         "interpolate",
      //         ["linear"],
      //         ["zoom"],
      //         0,
      //         1,
      //         9,
      //         3,
      //       ],
      //       // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      //       // Begin color ramp at 0-stop with a 0-transparency color
      //       // to create a blur-like effect.
      //       "heatmap-color": [
      //         "interpolate",
      //         ["linear"],
      //         ["heatmap-density"],
      //         0,
      //         "rgba(33,102,172,0)",
      //         0.2,
      //         "rgb(103,169,207)",
      //         0.4,
      //         "rgb(209,229,240)",
      //         0.6,
      //         "rgb(253,219,199)",
      //         0.8,
      //         "rgb(239,138,98)",
      //         1,
      //         "rgb(178,24,43)",
      //       ],
      //       // Adjust the heatmap radius by zoom level
      //       "heatmap-radius": [
      //         "interpolate",
      //         ["linear"],
      //         ["zoom"],
      //         0,
      //         2,
      //         9,
      //         20,
      //       ],
      //       // Transition from heatmap to circle layer by zoom level
      //       "heatmap-opacity": [
      //         "interpolate",
      //         ["linear"],
      //         ["zoom"],
      //         7,
      //         1,
      //         9,
      //         0,
      //       ],
      //     },
      //   },
      //   "waterway"
      // );

      setMapLoaded(true);
      onMapSettle();
    });

    return () => {
      mapRef.current.remove();
    };
  }, []);

  return <div id="PortalMap"></div>;
};

export default PortalMap;
