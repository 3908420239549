// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Acme&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:900i);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Passion+One:400,900);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,body{height:100%;margin:0;padding:0;margin:0px;height:100%;margin:0px;padding:0px}body{overflow-x:hidden;overflow-y:hidden}`, "",{"version":3,"sources":["webpack://./src/scss/App.scss"],"names":[],"mappings":"AAOA,UAEE,WAAA,CACA,QAAA,CACA,SAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CAGF,KACE,iBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
