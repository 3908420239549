// this is a react component used to generate and downlaod the user plugin
// it needs to get a neew token and use it to fetch the plugin and then download it

import React, { useState, useContext, useMemo } from "react";

import {
  Button,
  Classes,
  Drawer,
  DrawerSize,
  Position,
  Intent,
  Switch,
  Card,
} from "@blueprintjs/core";

import { AuthContext } from "../providers/AuthProvider";

const DEV_ENDPOINT = "http://localhost:3010";
const BACKEND_URL = process.env.NODE_ENV === "production" ? "" : DEV_ENDPOINT;

export default function PluginButton() {
  const { fetchNewPluginToken } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);

    const response = await fetchNewPluginToken();

    // // open link in new tab
    const link = `${BACKEND_URL}/plugin/${response.pluginToken}/portal.blue.user.js`;
    const openWindow = window.open(link, "_blank");

    // greasemonkey should immediately intercept it
    // setTimeout(() => {
    //   openWindow.close();
    // }, 5000);

    setLoading(false);
  };

  const pluginInstalled = useMemo(() => {
    // @ts-ignore
    if (!window.portalBluePlugin) {
      return false;
    }

    // @ts-ignore
    return window.portalBluePlugin;
  }, []);

  console.log("pluginInstalled", pluginInstalled);

  return (
    <Button
      intent={pluginInstalled ? Intent.SUCCESS : Intent.PRIMARY}
      className="plugin-button"
      onClick={handleDownload}
    >
      {loading
        ? "Loading..."
        : pluginInstalled
        ? // @ts-ignore
          `Plugin Installed ${pluginInstalled.version}!`
        : "Install IITC Plugin"}
    </Button>
  );
}
