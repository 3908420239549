import React, { useContext } from "react";

import PortalMap from "../components/PortalMap";
import AppDrawer from "../components/AppDrawer";

import {
  PortalContext,
  PortalProviderContext,
} from "../providers/PortalProvider";

import "../scss/MapPage.scss";
function MapPage() {
  return (
    <div className="App">
      <PortalProviderContext>
        <AppDrawer />

        <PortalMap />

        {/* <div id="hover-box"></div>
      <div id="info-box">
        <span
          id="fuckoffinfoboxplz"
          // onClick="ia.container.infoBox.style.display = 'none';return false;"
        >
          
        </span>
        <span id="info-box-inner"></span>
      </div> */}
      </PortalProviderContext>
    </div>
  );
}

export default MapPage;
